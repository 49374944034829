import {
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListSubheader,
    Typography
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { menuWidth } from '../config';
import { AdminRole } from './AdminRole';
import { appPaths } from 'src/AppRouterService';
import { BasicUser } from './BasicUserOrAdminRole';

interface Props extends WithStyles<typeof styles> {
    isMobileMenuOpen: boolean;
    onDrawerClose: () => void;
}

const styles = theme =>
    createStyles({
        drawerPaper: {
            width: menuWidth,
            [theme.breakpoints.up('lg')]: {
                position: 'fixed'
            }
        },
        toolbar: {
            display: 'flex' as 'flex',
            alignItems: 'center' as 'center',
            justifyContent: 'center' as 'center',
            padding: '0 8px',
            ...theme.mixins.toolbar
        }
    });

const orderMenuItems = [
    { path: '/order', label: 'Orders' },
    {
        path: appPaths.ordersExport,
        label: 'menu.ordersStatsExport',
        isAdmin: true
    },
    {
        path: appPaths.userChangelog,
        label: 'menu.userChangelog',
        isAdmin: true
    }
    // { path: '/product', label: 'Products' },
];

const overviewMenuItems = [
    { path: '/overview/open-orders', label: 'menu.openOrdersOverview' },
    { path: appPaths.overviewCartages, label: 'menu.cartagesOverview' },
    {
        path: appPaths.overviewCourierShipping,
        label: 'menu.courierShipping'
    },
    {
        path: appPaths.overviewCartageExpeditionTime,
        label: 'menu.cartageTimeOverview'
    }
    // { path: '/overview/expedition-stats', label: 'menu.expeditionStats' }
];

const shippingMenuItems = [
    {
        path: appPaths.shippingPlanning,
        label: 'menu.shippingPlanning',
        isBasicUserOrAdmin: true
    },
    {
        path: appPaths.shippingOrdersPrinting,
        label: 'menu.shippingOrdersPrinting',
        isBasicUserOrAdmin: true
    },
    {
        path: appPaths.printCourierLabels,
        label: 'menu.printCourierLabels',
        isBasicUserOrAdmin: true
    },
    {
        path: appPaths.cartagePlanning,
        label: 'menu.cartageOrders',
        isBasicUserOrAdmin: true
    },
    {
        path: appPaths.cartageList,
        label: 'menu.cartages'
    },
    {
        path: appPaths.markAsDone,
        label: 'menu.markAsDoneScanner',
        isBasicUserOrAdmin: true
    },
    {
        path: appPaths.cartageGroups,
        label: 'menu.cartageGroups',
        isBasicUserOrAdmin: true
    }
];

const settingsMenuItems = [
    { path: '/shop', label: 'Shops' },
    { path: '/user', label: 'Users', isAdmin: true },
    {
        path: appPaths.productsAvailability,
        label: 'menu.productsInfo',
        isAdmin: true
    }
];

const getRenderNavLink = onDrawerClose => ({
    path,
    label,
    isAdmin = false,
    isBasicUserOrAdmin = false
}) => {
    const navLink = (
        <NavLink to={path} exact key={path}>
            <ListItem button onClick={onDrawerClose}>
                {i18next.t(label)}
            </ListItem>
        </NavLink>
    );

    if (isBasicUserOrAdmin) {
        return <BasicUser key={path}>{navLink}</BasicUser>;
    }

    if (isAdmin) {
        return <AdminRole key={path}>{navLink}</AdminRole>;
    }

    return navLink;
};

const renderDrawer = (classes, onDrawerClose?) => (
    <div>
        <div className={classes.toolbar}>
            <Typography variant="subtitle1" color="inherit" noWrap>
                J.A.R.V.I.S.
            </Typography>
        </div>
        <BasicUser>
            <Divider />
            <List
                subheader={
                    <ListSubheader>
                        {i18next.t('menu.ordersSubtitle')}
                    </ListSubheader>
                }
            >
                {orderMenuItems.map(getRenderNavLink(onDrawerClose))}
            </List>
        </BasicUser>
        <Divider />
        <List
            subheader={
                <ListSubheader>
                    {i18next.t('menu.expeditionSubtitle')}
                </ListSubheader>
            }
        >
            {shippingMenuItems.map(getRenderNavLink(onDrawerClose))}
        </List>
        <Divider />
        <BasicUser>
            <Divider />
            <List
                subheader={
                    <ListSubheader>
                        {i18next.t('menu.overviewSubtitle')}
                    </ListSubheader>
                }
            >
                {overviewMenuItems.map(getRenderNavLink(onDrawerClose))}
            </List>
        </BasicUser>
        <Divider />
        <List
            subheader={
                <ListSubheader>
                    {i18next.t('menu.adminSettingsSubtitle')}
                </ListSubheader>
            }
        >
            {settingsMenuItems.map(getRenderNavLink(onDrawerClose))}
        </List>
    </div>
);

const MenuView = withStyles(styles)(
    ({ isMobileMenuOpen, onDrawerClose, classes }: Props) => (
        <div className="no-print">
            <Hidden lgUp>
                <Drawer
                    variant="temporary"
                    open={isMobileMenuOpen}
                    onClose={onDrawerClose}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {renderDrawer(classes, onDrawerClose)}
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    {renderDrawer(classes)}
                </Drawer>
            </Hidden>
        </div>
    )
);

export { MenuView };
