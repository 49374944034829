import { request, RequestMethod } from '../utils/request';
import { useCallback, useReducer } from 'react';
import { showError } from './errors/ErrorsActions';
import { useDispatch } from 'react-redux';
import { isNotificationError } from './errors/ErrorsService';
import { stringify } from 'qs';

type Hook<Response> = [
    (data?: object, requestUrl?: string) => Promise<Response>,
    {
        response?: Response;
        inProgress: boolean;
        hasFailed: boolean;
        hasSucceed: boolean;
    }
];

export enum ActionType {
    setSuccess = 'setSuccess',
    setFailure = 'setFailure',
    setInProgress = 'setInProgress'
}

export enum ApiStatus {
    none = 'none',
    inProgress = 'inProgress',
    success = 'success',
    failure = 'failure'
}

interface ApiState {
    response?: any;
    status: ApiStatus;
}

type Action =
    | { type: ActionType.setSuccess; response: any }
    | { type: ActionType.setFailure }
    | { type: ActionType.setInProgress };

const initialState: ApiState = {
    status: ApiStatus.none
};

function reducer(state: ApiState, action: Action): ApiState {
    switch (action.type) {
        case ActionType.setSuccess:
            return {
                ...state,
                response: action.response,
                status: ApiStatus.success
            };
        case ActionType.setFailure:
            return {
                ...state,
                status: ApiStatus.failure
            };
        case ActionType.setInProgress:
            return {
                ...state,
                status: ApiStatus.inProgress
            };
        default:
            return state;
    }
}

interface RequestOptions {
    showErrorDialog?: boolean;
    apiPort?: string;
}

const DEFAULT_OPTIONS: RequestOptions = {
    showErrorDialog: true
};

export function useRequest<Response>(
    method: RequestMethod,
    url?: string,
    query?: object,
    options: RequestOptions = DEFAULT_OPTIONS
): Hook<Response> {
    const dispatch = useDispatch();
    const [apiState, hookDispatch] = useReducer(reducer, initialState);
    const stringifiedQuery = stringify(query);

    const requestApi = useCallback(
        async (data?: object, requestUrl?: string): Promise<Response> => {
            hookDispatch({ type: ActionType.setInProgress });

            try {
                const requestResponse = await request<Response>({
                    method,
                    url: url ?? requestUrl,
                    query,
                    data,
                    apiPort: options.apiPort
                });
                hookDispatch({
                    type: ActionType.setSuccess,
                    response: requestResponse
                });
                return requestResponse;
            } catch (err) {
                hookDispatch({
                    type: ActionType.setFailure
                });
                if (options.showErrorDialog) {
                    dispatch(showError(err));
                }
                return Promise.reject({
                    isNotificationError: isNotificationError(err)
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, method, stringifiedQuery, url, options.apiPort]
    );

    return [
        requestApi,
        {
            inProgress: apiState.status === ApiStatus.inProgress,
            hasSucceed: apiState.status === ApiStatus.success,
            hasFailed: apiState.status === ApiStatus.failure,
            response: apiState.response
        }
    ];
}
