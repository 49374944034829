import { FormControlLabel, Checkbox } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { isAccountant } from 'src/common/session/SessionService';
import { isWithoutInvoiceChangeAllowed } from './OrderAccountRightsService';
import { useSession } from 'src/common/session/useSession';
import { Order } from 'src/models/Order';

interface Props {
    order?: Order;
    onChange(event, isChecked): void;
}

export const WithoutInvoiceCheckbox: React.FC<Props> = ({
    order,
    onChange
}) => {
    const session = useSession();

    return (
        <FormControlLabel
            key="withoutInvoice"
            control={
                <Checkbox
                    onChange={onChange}
                    checked={order.withoutInvoice}
                    name="withoutInvoice"
                    color="primary"
                    disabled={
                        !!order.invoice?.id ||
                        !isWithoutInvoiceChangeAllowed(
                            order,
                            isAccountant(session)
                        )
                    }
                />
            }
            label={i18next.t('order.withoutInvoice')}
        />
    );
};
