import { Button, Chip, Grid } from '@material-ui/core';
import React from 'react';
import EuroIcon from '@material-ui/icons/Euro';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BusinessIcon from '@material-ui/icons/Business';
import ProductInfoMissingIcon from '@material-ui/icons/FeaturedPlayList';
import i18next from 'i18next';
import { Order, OrderFlag } from 'src/models/Order';
import { toggleArrayItem } from 'src/common/UtilsService';
import { useSession } from 'src/common/session/useSession';
import { isAccountant } from 'src/common/session/SessionService';
import { isPriceChangeConfirmationAllowed } from './OrderAccountRightsService';

interface Props {
    disabled: boolean;
    order: Order | undefined;
    showZeroTaxFlag: boolean;
    onOrderChange: (propertyName: string, value: any) => void;
}

const ORDER_FLAGS_ATTR_NAME = 'flags';

export const FlagsView: React.FC<Props> = ({
    disabled,
    order,
    showZeroTaxFlag,
    onOrderChange
}) => {
    const session = useSession();
    const flags = order?.flags ?? [];
    const toggleFlag = (flag: OrderFlag): void => {
        onOrderChange(ORDER_FLAGS_ATTR_NAME, toggleArrayItem(flags, flag));
    };

    return (
        <Grid container justify="space-between">
            <Grid item>
                {showZeroTaxFlag && (
                    <Chip
                        disabled={disabled}
                        icon={<EuroIcon />}
                        label={i18next.t('order.zeroTaxFlag')}
                        style={{ marginRight: '16px' }}
                    />
                )}
                {flags.includes(OrderFlag.PriceChanged) && (
                    <Chip
                        disabled={
                            disabled ||
                            !isPriceChangeConfirmationAllowed(
                                order,
                                isAccountant(session)
                            )
                        }
                        icon={<EuroIcon />}
                        label={i18next.t('order.priceChangedFlag')}
                        style={{ marginRight: '16px' }}
                        onDelete={() => {
                            onOrderChange(
                                ORDER_FLAGS_ATTR_NAME,
                                toggleArrayItem(flags, OrderFlag.PriceChanged)
                            );
                        }}
                        color="secondary"
                    />
                )}
                {flags.includes(OrderFlag.ProductInfoMissing) && (
                    <Chip
                        disabled={disabled}
                        icon={<ProductInfoMissingIcon />}
                        label={i18next.t('order.productInfoMissingFlag')}
                        style={{ marginRight: '16px' }}
                        onDelete={() =>
                            toggleFlag(OrderFlag.ProductInfoMissing)
                        }
                        color="secondary"
                    />
                )}
                {flags.includes(OrderFlag.DoubleCheck) && (
                    <Chip
                        disabled={disabled}
                        icon={<DoneAllIcon />}
                        label={i18next.t('order.doubleCheckFlag')}
                        onDelete={() => toggleFlag(OrderFlag.DoubleCheck)}
                        color="primary"
                    />
                )}
                {flags.includes(OrderFlag.B2B) && (
                    <Chip
                        disabled={disabled}
                        icon={<BusinessIcon />}
                        label={i18next.t('order.b2bFlag')}
                        onDelete={() => toggleFlag(OrderFlag.B2B)}
                    />
                )}
            </Grid>
            <Grid item>
                <Button
                    startIcon={<DoneAllIcon />}
                    disabled={disabled || flags.includes(OrderFlag.DoubleCheck)}
                    onClick={() => toggleFlag(OrderFlag.DoubleCheck)}
                >
                    {i18next.t('order.addDoubleCheckFlag')}
                </Button>

                <Button
                    style={{ marginLeft: '10px' }}
                    startIcon={<BusinessIcon />}
                    disabled={disabled || flags.includes(OrderFlag.B2B)}
                    onClick={() => toggleFlag(OrderFlag.B2B)}
                >
                    {i18next.t('order.addB2BFlag')}
                </Button>
            </Grid>
        </Grid>
    );
};
