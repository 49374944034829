import i18next from 'i18next';
import React from 'react';
import { copyToClipboard } from 'src/common/CopyService';
import { CourierPackage, CourierShippingInfo } from 'src/models/Order';
import { CourierCompany } from 'src/scanner/printCourierLabels/PrintCourierLabelsService';

interface Props {
    shippingInfo: CourierShippingInfo;
}

const COURIER_LINKS = {
    [CourierCompany.GLS]: 'https://gls-group.eu/SK/sk/sledovanie-zasielok',
    [CourierCompany.KURIER_123]:
        'https://123kurier.jpsoftware.sk/sledovanie-zasielky'
};

const getPackageNumbers = (packages: CourierPackage[]): string =>
    packages.map(({ number }) => number).join(',');

export const CourierPackagesLink: React.FC<Props> = ({ shippingInfo }) => {
    const handleOnLinkClick = (): void => {
        copyToClipboard(getPackageNumbers(shippingInfo.packages));
    };
    return (
        <a
            href={COURIER_LINKS[shippingInfo.courier]}
            rel="noopener noreferrer"
            target="_blank"
            onClick={handleOnLinkClick}
        >
            {i18next.t(`order.displayCourierShipping_${shippingInfo.courier}`)}
        </a>
    );
};
