import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
import { useSharedData } from 'src/common/sharedData/useSharedData';
import { BasicUser } from 'src/components/BasicUserOrAdminRole';
import { CartageStatus } from 'src/models/Cartage';

export const CartageListAppBarView: React.FC = () => {
    const [
        cartageStatus = CartageStatus.INITIAL,
        setCartageStatus
    ] = useSharedData<CartageStatus>(SHARED_DATA_KEY.cartageList);

    const handleCartageStatusChange = evt => {
        setCartageStatus(evt.currentTarget.value);
    };

    return (
        <BasicUser>
            <RadioGroup
                row
                style={{ flexWrap: 'nowrap', padding: '0 20px' }}
                value={cartageStatus}
                onChange={handleCartageStatusChange}
            >
                <FormControlLabel
                    value={CartageStatus.INITIAL}
                    control={<Radio />}
                    label={i18next.t('cartageList.initialCartage')}
                />
                <FormControlLabel
                    value={CartageStatus.READY_TO_SHIP}
                    control={<Radio />}
                    label={i18next.t('cartageList.readyToShipCartage')}
                />
                <FormControlLabel
                    value={CartageStatus.DONE}
                    control={<Radio />}
                    label={i18next.t('cartageList.doneCartage')}
                />
            </RadioGroup>
        </BasicUser>
    );
};
