import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { isAuthenticated, isBasicUser } from '../common/session/SessionService';
import { SessionState } from '../common/session/SessionState';
import { AppState } from '../models/AppState';
import { LayoutContainer } from './Layout/LayoutContainer';

interface Props extends RouteProps {
    session: SessionState;
    isAuthorized?: (session: SessionState) => boolean;
}

export class PrivateRouteBase extends Component<Props> {
    static defaultProps = {
        isAuthorized: isBasicUser
    };

    render() {
        const {
            component: Component,
            session,
            isAuthorized,
            ...rest
        } = this.props;
        return (
            <Route
                {...rest}
                render={props => {
                    if (isAuthenticated(session) && isAuthorized(session)) {
                        return (
                            <LayoutContainer>
                                <Component {...props} />
                            </LayoutContainer>
                        );
                    }

                    const redirectTo = isAuthenticated(session)
                        ? '/'
                        : '/login';
                    return (
                        <Redirect
                            to={{
                                pathname: redirectTo,
                                state: { from: props.location }
                            }}
                        />
                    );
                }}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    session: state.session
});

const PrivateRoute = connect(mapStateToProps)(PrivateRouteBase);

export { PrivateRoute };
