import { FormControlLabel, Checkbox } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { isAccountant } from 'src/common/session/SessionService';
import { isIsPaidChangeAllowed } from './OrderAccountRightsService';
import { useSession } from 'src/common/session/useSession';
import { Order } from 'src/models/Order';

interface Props {
    order?: Order;
    onChange(event, isChecked): void;
}

export const IsPaidCheckbox: React.FC<Props> = ({ order, onChange }) => {
    const session = useSession();

    return (
        <FormControlLabel
            key="isPaid"
            control={
                <Checkbox
                    onChange={onChange}
                    checked={order?.isPaid}
                    name="isPaid"
                    color="primary"
                    disabled={
                        !isIsPaidChangeAllowed(order, isAccountant(session))
                    }
                />
            }
            label={i18next.t('Paid')}
        />
    );
};
