import { IconButton, Tooltip } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import i18next from 'i18next';
import React from 'react';
import { tooltipEnterDelay } from 'src/config';
import { Order } from 'src/models/Order';
import {
    isCloneOrderShown,
    isDeleteInvoiceDisabled,
    isExportInvoiceDisabled,
    isOrderWithRegularInvoice
} from './OrderAppBarService';
import {
    getInvoiceUrl,
    isOrderWithoutInvoice,
    isOrderCanceled,
    isOrderReceived
} from '../OrderService';
import { ShippingChangeButtons } from './ShippingChangeButtons';
import { CreateClaimButton } from './CreateClaimButtom';
import { AdminRole } from 'src/components/AdminRole';
import { ResetOrderButton } from './ResetOrderButton';
import { CancelOrderButton } from './CancelOrderButton';
import { SaveOrderButton } from './SaveOrderButton';
import { CloneOrderButton } from './CloneOrderButton';

const styles = theme =>
    createStyles({
        root: {
            width: '100%',
            overflowY: 'auto',
            display: 'flex'
        },
        iconWrapper: {
            display: 'inline-block',
            margin: '0 5px'
        },
        invoiceIconsWrapper: {
            display: 'flex',
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2)
        },
        euroIcon: {
            position: 'absolute',
            top: '6px',
            left: '6px',
            fontSize: '12px'
        }
    });

interface Props extends WithStyles<typeof styles> {
    order: Order;
    isLoading: boolean;
    isSaving: boolean;
    isEditing: boolean;
    isExportingInvoice: boolean;
    isDeletingInvoice: boolean;
    onRestoreClick: () => void;
    onExportInvoiceClick: () => void;
    onDeleteInvoiceClick: () => void;
    onReadyToShipClick: () => void;
    onCreateRegularInvoiceClick: () => void;
    onRefreshOrderClick: () => void;
}

interface State {
    multiActionAnchorEl: HTMLElement;
}

class OrderAppBarViewBase extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            multiActionAnchorEl: null
        };
    }

    isCreateRegularInvoiceDisabled() {
        const { order, isExportingInvoice } = this.props;
        return (
            isExportingInvoice ||
            isOrderWithoutInvoice(order) ||
            isOrderCanceled(order) ||
            isOrderWithRegularInvoice(order)
        );
    }

    renderRestoreOrder() {
        const { order, onRestoreClick, classes } = this.props;

        if (!isOrderCanceled(order)) {
            return;
        }

        return (
            <Tooltip
                title={i18next.t('Restore order')}
                enterDelay={tooltipEnterDelay}
            >
                <div className={classes.iconWrapper}>
                    <IconButton color="inherit" onClick={onRestoreClick}>
                        <SettingsBackupRestoreIcon />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    renderExportInvoice() {
        const {
            isExportingInvoice,
            isEditing,
            order,
            onExportInvoiceClick,
            classes
        } = this.props;

        if (isOrderReceived(order)) {
            return;
        }

        return (
            <Tooltip
                title={i18next.t('Export invoice')}
                enterDelay={tooltipEnterDelay}
            >
                <div className={classes.iconWrapper}>
                    <IconButton
                        color="inherit"
                        disabled={
                            isEditing ||
                            isExportInvoiceDisabled(order, isExportingInvoice)
                        }
                        onClick={onExportInvoiceClick}
                    >
                        <ArrowUpwardIcon />
                        <EuroSymbolIcon className={classes.euroIcon} />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    renderDownloadInvoice() {
        const { order, classes, isExportingInvoice } = this.props;

        if (isOrderReceived(order)) {
            return;
        }

        return (
            <Tooltip
                title={i18next.t('Download invoice')}
                enterDelay={tooltipEnterDelay}
            >
                <div className={classes.iconWrapper}>
                    <IconButton
                        color="inherit"
                        disabled={
                            isExportingInvoice || isOrderWithoutInvoice(order)
                        }
                        href={order.invoice && getInvoiceUrl(order)}
                    >
                        <ArrowDownwardIcon />
                        <EuroSymbolIcon className={classes.euroIcon} />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    renderDeleteInvoice() {
        const {
            order,
            classes,
            isExportingInvoice,
            isDeletingInvoice,
            onDeleteInvoiceClick
        } = this.props;

        if (isOrderReceived(order)) {
            return;
        }

        return (
            <Tooltip
                title={i18next.t('Delete invoice')}
                enterDelay={tooltipEnterDelay}
            >
                <div className={classes.iconWrapper}>
                    <IconButton
                        color="inherit"
                        disabled={isDeleteInvoiceDisabled(
                            isExportingInvoice,
                            isDeletingInvoice,
                            order
                        )}
                        onClick={onDeleteInvoiceClick}
                    >
                        <DeleteIcon />
                        <EuroSymbolIcon className={classes.euroIcon} />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    renderCreateRegularInvoice() {
        const {
            order,
            classes,
            isEditing,
            onCreateRegularInvoiceClick
        } = this.props;

        if (isOrderReceived(order)) {
            return;
        }

        return (
            <Tooltip
                title={i18next.t('Create regular invoice')}
                enterDelay={tooltipEnterDelay}
            >
                <div className={classes.iconWrapper}>
                    <IconButton
                        color="inherit"
                        disabled={
                            isEditing || this.isCreateRegularInvoiceDisabled()
                        }
                        onClick={onCreateRegularInvoiceClick}
                    >
                        <CheckIcon />
                        <EuroSymbolIcon className={classes.euroIcon} />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    renderRefreshOrder() {
        const { onRefreshOrderClick, classes } = this.props;

        return (
            <Tooltip
                title={i18next.t('Refresh order')}
                enterDelay={tooltipEnterDelay}
                style={{ marginLeft: 'auto' }}
            >
                <div className={classes.iconWrapper}>
                    <IconButton color="inherit" onClick={onRefreshOrderClick}>
                        <RefreshIcon />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    render() {
        const { order, isLoading, isEditing, classes } = this.props;
        if (isLoading || !order) {
            return null;
        }

        return (
            <div className={classes.root}>
                {this.renderRefreshOrder()}
                <ShippingChangeButtons
                    order={order}
                    buttonClassName={classes.iconWrapper}
                />
                {!order.withoutInvoice && (
                    <div className={classes.invoiceIconsWrapper}>
                        {this.renderDeleteInvoice()}
                        {this.renderCreateRegularInvoice()}
                        {this.renderDownloadInvoice()}
                        {this.renderExportInvoice()}
                    </div>
                )}
                <AdminRole>
                    <ResetOrderButton
                        orderId={order._id}
                        isShown={!isOrderReceived(order)}
                        className={classes.iconWrapper}
                    />
                </AdminRole>
                <CancelOrderButton
                    className={classes.iconWrapper}
                    order={order}
                />
                {this.renderRestoreOrder()}
                <CreateClaimButton
                    disabled={isEditing}
                    orderId={order._id}
                    isShown={!order.isClaim}
                    className={classes.iconWrapper}
                />
                <CloneOrderButton
                    disabled={isEditing}
                    orderId={order._id}
                    isShown={isCloneOrderShown(order)}
                    className={classes.iconWrapper}
                />
                <SaveOrderButton
                    isEditing={isEditing}
                    className={classes.iconWrapper}
                    isSaving={this.props.isSaving}
                    order={order}
                />
            </div>
        );
    }
}

const OrderAppBarView = withStyles(styles)(OrderAppBarViewBase);

export { OrderAppBarView };
