const IFRAME_CLEAN_UP_TIMEOUT = 1000 * 60;

function onPrintFileCleanup(iframe: HTMLIFrameElement): void {
    document.body.removeChild(iframe);
    window.URL.revokeObjectURL(iframe.src);
}

export enum CourierCompany {
    GLS = 'GLS',
    KURIER_123 = 'KURIER_123'
}

export function printBlob(blob: any): void {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = window.URL.createObjectURL(blob);
    document.body.appendChild(iframe);

    iframe.contentWindow.print();

    setTimeout(() => onPrintFileCleanup(iframe), IFRAME_CLEAN_UP_TIMEOUT);
}

export function removeOrderNumberPrefix(value = ''): string {
    return value.replace(/J%/, '');
}
