import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { isAdmin, isAuthenticated } from './common/session/SessionService';
import { PrivateRoute } from './components/PrivateRoute';
import { LoginContainer } from './login/LoginContainer';
import { OrderContainer } from './order/OrderContainer';
import { OrderListContainer } from './orderList/OrderListContainer';
import { ShopListContainer } from './shopList/ShopListContainer';
import { UserListContainer } from './userList/UserListContainer';

import { OverviewView } from './overview/open-orders/OverviewView';
import { ProductListTable } from './productList/ProductListTable';
import { ProductView } from './product/ProductView';
import { AddProductView } from './product/AddProductView';
import { appPaths } from './AppRouterService';
import { CartageList } from './cartageList/CartageList';
import { CartageView } from './cartage/CartageView';
import { MarkAsDoneView } from './scanner/markAsDone/MarkAsDoneView';
import { PrintCourierLabels } from './scanner/printCourierLabels/PrintCourierLabelsView';
import { ShippingPlanning } from './shippingPlanning/ShippingPlanning';
import { ShippingOrdersPrinting } from './shippingPlanning/ShippingOrdersPrinting';
import { ExpeditionStatsView } from './overview/expedition-stats/ExpeditionStatsView';
import { UserChangelogView } from './user-changelog/UserChangelogView';
import { OrdersExport } from './ordersExport/OrdersExport';
import { CartageGroupList } from './cartageGroups/CartageGroupList';
import { AddCartageGroupContainer } from './cartageGroup/AddCartageGroupContainer';
import { EditCartageGroupContainer } from './cartageGroup/EditCartageGroupContainer';
import { CartagePlanningView } from './cartagePlanning/CartagePlanningView';
import { CartagePlanningGroupView } from './cartagePlanningGroup/CartagePlanningGroupView';
import { CartagePlanningOrdersWithoutGroupView } from './cartagePlanningGroup/CartagePlanningOrdersWithoutGroupView';
import { CustomRedirect } from './CustomRedirect';
import { CartagesOverview } from './overview/cartages/CartagesOverview';
import { CourierShippingOverview } from './overview/courier-shipping/CourierShippingOverview';
import { CartagesExpeditionTime } from './overview/cartages-expedition-time/CartagesExpeditionTime';
import { ProductsAvailability } from './products-availability/ProductsAvailability';

export const AppRoutes: React.FC = () => (
    <>
        <Switch>
            <Route exact path={appPaths.login} component={LoginContainer} />
            <PrivateRoute
                exact
                path={appPaths.userChangelog}
                component={UserChangelogView}
                isAuthorized={isAdmin}
            />
            <PrivateRoute
                exact
                path={appPaths.ordersExport}
                component={OrdersExport}
                isAuthorized={isAdmin}
            />
            <PrivateRoute
                exact
                path={appPaths.orderList}
                component={OrderListContainer}
            />
            <PrivateRoute path={appPaths.order} component={OrderContainer} />
            <PrivateRoute
                exact
                path={appPaths.shopList}
                component={ShopListContainer}
            />
            <PrivateRoute
                exact
                path={appPaths.productList}
                component={ProductListTable}
            />
            <PrivateRoute
                exact
                path={appPaths.addNewProduct}
                component={AddProductView}
            />
            <PrivateRoute
                exact
                path={appPaths.product}
                component={ProductView}
            />
            <PrivateRoute
                exact
                path={appPaths.userList}
                component={UserListContainer}
                isAuthorized={isAdmin}
            />
            <PrivateRoute
                exact
                path={appPaths.overviewOpenOrders}
                component={OverviewView}
            />
            <PrivateRoute
                exact
                path={appPaths.overviewExpeditionStats}
                component={ExpeditionStatsView}
            />
            <PrivateRoute
                exact
                path={appPaths.shippingPlanning}
                component={ShippingPlanning}
            />
            <PrivateRoute
                exact
                path={appPaths.shippingOrdersPrinting}
                component={ShippingOrdersPrinting}
            />
            <PrivateRoute
                exact
                path={appPaths.cartageList}
                component={CartageList}
                isAuthorized={isAuthenticated}
            />
            <PrivateRoute
                exact
                path={appPaths.cartage}
                component={CartageView}
                isAuthorized={isAuthenticated}
            />
            <PrivateRoute
                exact
                path={appPaths.overviewCartages}
                component={CartagesOverview}
                isAuthorized={isAuthenticated}
            />
            <PrivateRoute
                exact
                path={appPaths.markAsDone}
                component={MarkAsDoneView}
            />
            <PrivateRoute
                exact
                path={appPaths.printCourierLabels}
                component={PrintCourierLabels}
            />
            <PrivateRoute
                exact
                path={appPaths.cartageGroups}
                component={CartageGroupList}
            />
            <PrivateRoute
                exact
                path={appPaths.addCartageGroup}
                component={AddCartageGroupContainer}
            />
            <PrivateRoute
                exact
                path={appPaths.editCartageGroup}
                component={EditCartageGroupContainer}
            />
            <PrivateRoute
                exact
                path={appPaths.cartagePlanningOrderWithoutGroup}
                component={CartagePlanningOrdersWithoutGroupView}
            />
            <PrivateRoute
                exact
                path={appPaths.cartagePlanning}
                component={CartagePlanningView}
            />
            <PrivateRoute
                exact
                path={appPaths.cartagePlanningGroup}
                component={CartagePlanningGroupView}
            />
            <PrivateRoute
                exact
                path={appPaths.overviewCourierShipping}
                component={CourierShippingOverview}
            />
            <PrivateRoute
                exact
                path={appPaths.overviewCartageExpeditionTime}
                component={CartagesExpeditionTime}
            />
            <PrivateRoute
                exact
                path={appPaths.productsAvailability}
                component={ProductsAvailability}
                isAuthorized={isAdmin}
            />
            <CustomRedirect />
        </Switch>
    </>
);
