import { stringify } from 'qs';

const API_PORT = '1337';

const getApiHost = (apiPort = API_PORT): string =>
    `${window.location.protocol}//${window.location.hostname}:${apiPort}`;

export const apiHost = getApiHost();
interface RequestOptions {
    method: RequestMethod;
    url: string;
    query?: object;
    data?: object;
    apiPort?: string;
}

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}

export async function request<T = any>({
    method,
    url,
    query,
    data,
    apiPort
}: RequestOptions): Promise<T> {
    const queryString = query ? `?${stringify(query)}` : '';
    const response = await fetch(`${getApiHost(apiPort)}${url}${queryString}`, {
        method,
        body: getBody(data),
        headers: new Headers({
            'Content-type': 'application/json'
        }),
        credentials: 'include'
    });

    await checkResponse(response);

    return response.json();
}

export async function get<T>(url: string, query?: object): Promise<T> {
    return request<T>({ method: RequestMethod.GET, url, query });
}

export async function post(url: string, data?: object) {
    return request({ method: RequestMethod.POST, url, data });
}

export async function patch(url: string, id: string, data?: object) {
    return request({ method: RequestMethod.PATCH, url: `${url}/${id}`, data });
}

export async function del(url: string, id: string) {
    return request({ method: RequestMethod.DELETE, url: `${url}/${id}` });
}

function getBody(data?: object): string | undefined {
    if (!data) {
        return undefined;
    }

    return JSON.stringify(filterData(data));
}

const propsBlackList = ['createdAt', 'updatedAt'];

function filterData(data) {
    const filteredData = { ...data };
    propsBlackList.forEach(propName => {
        delete filteredData[propName];
    });
    return filteredData;
}

const loginPath = '/login';
async function checkResponse(response) {
    if (response.status === 401 && window.location.pathname !== loginPath) {
        window.location.pathname = loginPath;
    }

    if (response.status !== 200) {
        const responseJson = await response.json();
        const error = {
            status: response.status,
            ...responseJson
        };
        throw error;
    }
}
