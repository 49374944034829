import React, { useState, useCallback } from 'react';
import i18next from 'i18next';
import {
    Grid,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { ScannedOrders } from '../ScannedOrders';
import {
    CourierCompany,
    printBlob,
    removeOrderNumberPrefix
} from './PrintCourierLabelsService';
import { RequestMethod } from 'src/utils/request';
import { useRequest } from 'src/common/useRequestHook';
import { API_URLS } from 'src/common/ApiService';
import {
    useScannedOrdersReducer,
    ScannedOrderState
} from '../useScannedOrdersReducer';

const DEFAULT_PARTS_COUNT = '1';

export const PrintCourierLabels: React.FC = () => {
    const [isReprint, setIsReprint] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [courier, setCourier] = useState(CourierCompany.GLS);
    const [partsCount, setPartsCount] = useState<string>(DEFAULT_PARTS_COUNT);
    const [state, dispatch] = useScannedOrdersReducer();
    const [createLabels, { inProgress }] = useRequest<number[]>(
        RequestMethod.POST,
        API_URLS.printCourierLabels
    );

    const handleLabelsPrint = useCallback(
        evt => {
            evt.preventDefault();
            if (!orderNumber || !partsCount) {
                return;
            }

            createLabels({
                orderNumber,
                partsCount,
                courier,
                isReprint
            })
                .then(binaryData => {
                    printBlob(
                        new Blob([new Uint8Array(binaryData).buffer], {
                            type: 'application/pdf'
                        })
                    );
                    dispatch({
                        state: ScannedOrderState.SUCCEED,
                        orderNumber
                    });
                })
                .catch(() => {
                    dispatch({
                        state: ScannedOrderState.FAILED,
                        orderNumber
                    });
                })
                .finally(() => {
                    setOrderNumber('');
                    setIsReprint(false);
                });
        },
        [orderNumber, courier, createLabels, partsCount, isReprint, dispatch]
    );

    const handleOrderNumberChange = evt => {
        setOrderNumber(removeOrderNumberPrefix(evt.currentTarget.value));
    };

    const handleIsReprintChange = () => {
        setIsReprint(!isReprint);
    };

    const handleCourierChange = useCallback(
        evt => {
            setCourier(evt.target.value);
        },
        [setCourier]
    );

    return (
        <>
            <h1>{i18next.t('printCourierLabels.title')}</h1>
            <form onSubmit={handleLabelsPrint}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={2} container justify="center">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="courier">
                                {i18next.t('printCourierLabels.courier')}
                            </InputLabel>
                            <Select
                                id="courier"
                                name="courier"
                                value={courier}
                                onChange={handleCourierChange}
                                fullWidth
                            >
                                <MenuItem value={CourierCompany.GLS}>
                                    GLS
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="partsCount">
                                {i18next.t('printCourierLabels.partsCount')}
                            </InputLabel>
                            <Input
                                id="partsCount"
                                type="number"
                                inputProps={{ min: 0 }}
                                name="partsCount"
                                value={partsCount}
                                onChange={event => {
                                    setPartsCount(event.currentTarget.value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="orderNumber">
                                {i18next.t('printCourierLabels.orderNumber')}
                            </InputLabel>
                            <Input
                                id="orderNumber"
                                value={orderNumber}
                                name="orderNumber"
                                onChange={handleOrderNumberChange}
                                disabled={inProgress}
                                autoComplete="off"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <CropFreeIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            key="isReprint"
                            control={
                                <Checkbox
                                    onChange={handleIsReprintChange}
                                    checked={isReprint}
                                    name="isReprint"
                                />
                            }
                            label={i18next.t('printCourierLabels.reprint')}
                        />
                    </Grid>
                    <button type="submit" style={{ display: 'none' }} />
                </Grid>
            </form>

            <h2>{i18next.t('printCourierLabels.scannedOrdersTitle')}</h2>
            <ScannedOrders
                orders={Object.values(state.orders).sort(
                    (order1, order2) => order2.date - order1.date
                )}
            />
        </>
    );
};
